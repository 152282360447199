export default {
  name: "AddManual",
  data() {
    return {
      form: {
        manual: {
          accountNumber: "",
          transactionCodeId: "",
          nominal: 0,
          description: "",
          savingId: "",
          cifIdname: "",
        },
      },
      options: {
        transactionCodeId: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    routeToPageList() {
      this.$router.push("/collector-funding-detail");
    },
    helperGetTransactionCodeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            page: 0,
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_COLLECTOR_FUNDING_MANUAL_TRX,
          },
          url: "transaction-code",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.transactionCodeId.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        });
    },
    async handleAddCollectorFundingManual() {
      var data = {
        accountNumber: this.form.manual.accountNumber,
        transactionCodeId: this.form.manual.transactionCodeId,
        nominal: this.form.manual.nominal,
        description: this.form.manual.description,
      };

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/saving-transaction-by-collector-funding",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.routeToPageList();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1500);
        this.property.animation.submitLoading = false;
      }
    },
    async getSavingAccountId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;

      const savingAccountId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).savingAccountId;

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/" + savingAccountId,
        });

        if (resp.data.code === "SUCCESS") {
          this.form.manual.accountNumber = resp.data.data.accountNumber;
          this.form.manual.cifIdname = resp.data.data.mcif?.cifIdName;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    changeOptionsTransactionCode() {
      this.options.transactionCodeId.map((index) => {
        if (index.value === this.form.manual.transactionCodeId) {
          this.form.manual.description = index.value
            ? `${index.description} An ${this.form.manual.cifIdname} `
            : "";
        }
      });
    },
  },
  mounted() {
    this.helperGetTransactionCodeDataForReference();
    this.getSavingAccountId();
  },
};
